import { Black, BlackRedTitle, Red } from "components/_ui/BlackRedTitle";
import { useFormik } from "formik";
import React, { useState, useRef } from "react";
import { basicSchema } from "schemas";
import FormBanner from "./FormBanner";
import { EMAILJS } from "config";
import axios from "axios";
import { Spinner } from "components/_ui";
import Success from "./Success";
import { useHistory } from "react-router";

const ContactForm = (props) => {
  const history = useHistory();

  const { children } = props;

  // console.log("children", children);
  const [showSuccessBanner, setShowSuccessbanner] = useState(false);

  const form = useRef();

  const onSubmit = async (values, actions) => {
    const data = {
      ...EMAILJS,
      template_params: values,
    };

    await axios.post("https://api.emailjs.com/api/v1.0/email/send", data).then(
      () => {
        actions.resetForm();
        setShowSuccessbanner(true);
        history.push("/message-sent");
      },
      (error) => {
        // console.log(error);
      }
    );
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      name: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  const showForm = () => {
    return (
      <form
        id="contact"
        ref={form}
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="d-flex flex-column input-group">
          <label htmlFor="name">What&apos;s your name?</label>
          <input
            id="name"
            type="text"
            value={values.name}
            placeholder="Firstname Lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`form-control w-100 rounded ${
              errors.name && touched.name && "input-error"
            }`}
          />
          {errors.name && touched.name && (
            <p className="error-message">{errors.name}</p>
          )}
        </div>
        <div className="d-flex flex-column input-group">
          <label htmlFor="email">
            What&apos;s the best email to reach you?
          </label>
          <input
            id="email"
            type="email"
            value={values.email}
            placeholder="username@email.com"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`form-control w-100 rounded  ${
              errors.email && touched.email ? "input-error" : ""
            }`}
          />
          {errors.email && touched.email && (
            <p className="error-message">{errors.email}</p>
          )}
        </div>
        <div className="d-flex flex-column input-group">
          <label htmlFor="message">What can we help you with?</label>
          <textarea
            id="message"
            type="text"
            value={values.message}
            placeholder="I need help with..."
            onChange={handleChange}
            onBlur={handleBlur}
            className={`form-control w-100 rounded ${
              errors.message && touched.message ? "input-error" : ""
            }`}
          />
          {errors.message && touched.message && (
            <p className="error-message">{errors.message}</p>
          )}
        </div>

        <button
          id="mbd-submit-contact-form"
          disabled={isSubmitting}
          type="submit"
          className="btn btn-mbd-primary float-right"
        >
          {" "}
          Submit
        </button>
      </form>
    );
  };

  const contactUs = () => {
    return (
      <>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <>
            <FormBanner />
            <div
              id="contact-us-form-wrap"
              className="form-wrap d-flex flex-column justify-content-start"
            >
              <BlackRedTitle tag="h1" classNames={"fw-700"}>
                <Red>We&apos;re all ears,</Red>
                <br />
                <Black>tell us more!</Black>
              </BlackRedTitle>
              {showForm()}
            </div>
          </>
        )}
      </>
    );
  };

  const redirection = () => {
    return (
      <>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <p className="fw-500">Redirecting...</p>
        </div>
      </>
    );
  };

  return (
    <div id="ContactUs" className="component">
      <div className="inner-wrap">
        {children
          ? children
          : !!!showSuccessBanner
          ? contactUs()
          : redirection()}
        {/* <Success/> */}
        {/* { contactUs()} */}
      </div>
    </div>
  );
};

export default ContactForm;
